.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  width: 100vw;
  height: 100vh;
}

.loader {
  border: 6px solid #d3d3d3;
  border-top: 6px solid #222222;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite;
}

.loader-nokemon {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-size: 24px;
  margin-top: 20px;
  color: white;
  display: flex;
  justify-content: center;
}

.period {
  animation: bounce 1s ease-in-out infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.period:nth-child(2) {
  animation-delay: 0.2s;
}

.period:nth-child(3) {
  animation-delay: 0.4s;
}
