.login-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.login-modal-hr {
  width: 80%;
  height: 1px;
  border-top: 1px solid black;
  margin: auto;
  /* margin-top: 30px; */
  margin-bottom: 16px;
}

.login-modal-logo {
  width: 100%;
  height: 100%;
  max-width: 200px;
  object-fit: contain;
  margin-top: 20px;
}

.LoginPromptModal-login-modal-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 22.6px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  width: 90%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.login-modal-subtitle-left {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #555;
  margin: 0;
  padding: 0;
  width: 80%;
  text-align: left;
}

.LoginPromptModal-login-modal-subtitle-center {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #555;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 22px;
  width: 80%;
  text-align: center;
}

.google-login-button-text {
  width: 90%;
  margin: 0;
}

.LoginPromptModal-google-login-button {
  width: 80%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #5e72e4;
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.google-login-button:hover {
  border: none;
  color: white;
  background-color: #5e72e4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.google-login-button:active {
  border: none;
  color: white;
  background-color: #1b3f93;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
}

.google-login-button:disabled {
  background-color: #ddd;
  color: #444;
  cursor: not-allowed;
  box-shadow: none;
}

.google-logo {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-image: url('https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png');
}

@media (max-width: 768px) {
  .LoginPromptModal-login-modal-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 22.6px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .google-login-button {
    font-size: 15px;
    width: 90%;
  }

  .login-modal-hr {
    width: 90%;
  }
  .LoginPromptModal-login-modal-subtitle-center {
    width: 90%;
  }
}
