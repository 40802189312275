.ButtonWithDescription-container {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10000;
}

.ButtonWithDescription-container:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.ButtonWithDescription-description {
  position: absolute;
  margin-top: 5px;
  background-color: #1d1d1d;
  border: 1px solid white;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: normal;
  color: white;
  min-width: 205px;
  max-width: 95%;
  text-align: center;
}

.ButtonWithDescription-description.fade-out {
  opacity: 0;
  transition: opacity 2s;
}

.ButtonWithDescription-sidebar-description {
  margin-top: 5px;
  background-color: #1d1d1d;
  padding: 10px 20px 10px 20px;
  font-size: 15 px;
  font-weight: normal;
  color: white;
  min-width: 150px;
  width: 150px;
  max-width: 95%;
  text-align: center;
}
