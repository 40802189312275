.ProductDisplay-container {
  display: flex;
  gap: 30px;
  width: 80%;
  justify-content: center;
}
.supportProductBody {
  width: 75%;
}

.ShopBody-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
}

.ShopBody-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .ProductDisplay-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  .supportProductBody {
    width: 90%;
  }
}

@media screen and (max-width: 970px) {
  .ProductDisplay-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  .ShopBody-container {
    width: 100%;
  }
  .supportProductBody {
    width: 100%;
  }
}

.ShopDisplay-image {
  display: flex;
  justify-content: center;
}

.ShopDisplay-image > .SVGBadgeComponent > svg {
  height: 125px;
  width: auto;
}
.ShopDisplay-image > .SVGBadgeComponent > img {
  height: 125px;
  width: auto;
}

.ShopDisplay-amount.nokepoints {
  display: flex;
  margin-left: -5px;
}

.ShopDisplay-amount.subscription {
  display: flex;
  /* margin-left: 70px; */
  margin-left: 40px;
}

.dollar-symbol {
  padding: 0;
  margin: 0;
  font-size: 2rem;
  color: #555;
  font-weight: 700;
}

.dollar-amount {
  padding: 0;
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  color: #555;
  text-align: center;
}

.cent-amount {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  font-size: 1rem;
  color: #999;
  vertical-align: top;
  margin-left: 0.25em;
  font-weight: 700;
}

.per-month {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  margin-left: 5px;
  font-size: 0.9rem;
  color: #999;
  text-align: center;
  font-weight: 700;
}
