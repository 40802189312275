.header-container {
  position: relative;
  background-color: #1d1d1d;
  color: white;
  font-weight: 600;
  font-size: 14px;
  height: 73.5px;
  width: 100%;
  border: 1px solid rgba(173, 173, 173, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
