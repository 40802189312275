.Container-main {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.Container-sidebar {
  width: 80px;
}

.Container-children {
  width: calc(100% - 80px);
}

@media (max-width: 499px) {
  .Container-children {
    width: 100%;
  }
  .Container-sidebar {
    width: 0px;
  }
}
