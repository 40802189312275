.GenericEmptyCollection-container {
  width: 80%;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GenericEmptyCollection-login-button {
  width: 400px;
}
