.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.5s ease-in-out;
  z-index: 1000000 !important;
}

@media (max-width: 600px) {
  .modal {
    width: 90%;
    max-width: 90vw;
    margin: 2rem auto;
    border-radius: 0;
  }
}

.modal {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 420px;
  max-width: 95vw;
  animation: slideIn 0.5s ease-in-out;
  max-height: 80vh;
  overflow: scroll;
  padding-bottom: 20px;
}

.modal-border {
  width: 100%;
  height: 40px;
  padding: 0;
  margin-bottom: 20px;
  background-color: #5e18eb;
  border-radius: 4px 4px 0 0;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.modal-close-button {
  top: -20px;
  left: calc(100% - 50px);
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-button:before {
  content: '×';
  font-size: 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
