.LandingPageMenu-main {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 73px;
  background-color: #191a1a;
  position: relative;
  background-color: #1d1d1d;
  color: white;
  height: 72.5px;
  border: 1px solid rgba(173, 173, 173, 0.2);
  margin-bottom: 25px;
}

.LandingPageMenu-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 20px;
}

.LandingPageMenu-logo {
  font-size: 24px;
  color: #eee;
  margin: 0;
  padding: 0;
}

.LandingPageMenu-right {
  display: flex;
  align-items: center;
}

.LandingPageMenu-user-logo-container {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-image: linear-gradient(
    180deg,
    #db4437 0% 25%,
    #4285f4 25% 50%,
    #0f9d58 50% 75%,
    #f4b400 75% 100%
  );
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.LandingPageMenu-user-logo {
  width: 90%;
  height: 90%;
  border-radius: 100%;
  object-fit: cover;
}

.LandingPageMenu-currency-value-free {
  height: 40px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(245, 158, 11);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out;
  border: 1px solid gold;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: right bottom;
  display: flex;
  align-items: center;
  gap: 10px;
}

.LandingPageMenu-currency-value-free:hover {
  background-position: left bottom;
}

.LandingPageMenu-currency-value-premium {
  height: 40px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: purple;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid gold;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
  display: flex;
  align-items: center;
  gap: 10px;
}

.LandingPageMenu-currency-value-learn-more {
  white-space: nowrap;
  height: 40px;
  font-size: 16px;
  padding: 10px 20px;
  background-color: purple;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid gold;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  display: flex;
  align-items: center;
}

@keyframes shine {
  from {
    background-position: -100% bottom;
  }
  to {
    background-position: 200% bottom;
  }
}

.LandingPageMenu-try-free {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #5e18eb;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-try-free:hover {
  background-color: #0bb3e3;
}

.LandingPageMenu-sign-in {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #191a1a;
  color: #eee;
  border: 1px solid #eee;
  border-radius: 20px;
  cursor: pointer;
}

.LandingPageMenu-subscribe-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #5e18eb;
  color: #eee;
  border: 1px solid #eee;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.SVGBadgeComponent > svg {
  height: 30px;
  width: auto;
}

/* #9f5eeb, fe973b,ff0103 */
.LandingPageMenu-subsciption-tier-sidebar-button {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-radius: 0 !important;
  width: 200px;
  margin-bottom: 10px;
}

.LandingPageMenu-subsciption-tier-sidebar-button.bronze {
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #b87333;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subsciption-tier-sidebar-button.silver {
  color: white;
  font-weight: 600;
  font-size: 16px;
  background-color: #0076cc;
  border: 1px solid #b8b8b8;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subsciption-tier-sidebar-button.gold {
  color: white;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #d4af37;
  background-color: #d61e1e;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subsciption-tier-button.bronze {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #2bbb5d;
  border: 1px solid #b87333;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subsciption-tier-button.silver {
  width: 150px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #0076cc;
  border: 1px solid #b8b8b8;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subsciption-tier-button.gold {
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #d4af37;
  background-color: #d61e1e;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: -100% bottom;
  animation: shine 3s ease-in-out infinite;
}

.LandingPageMenu-subscription-tier-button-text {
}

.LandingPageMenu-subsciption-tier-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px !important;
  height: 40px !important;
  padding: 0 15px 0 15px !important;
  margin: 0;
  cursor: pointer;
}

.LoggedInMenuRight-GoogleProfile-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  cursor: pointer;
}

.LoggedInMenuRight-GoogleProfile-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.LoggedInMenuRight-buttons {
  display: flex;
  opacity: 0;
  padding-right: 20px;
}

.LoggedInMenuRight-buttons.show {
  opacity: 1;
  animation: slide-in 1s;
}
.LoggedInMenuRight-buttons.hide {
  opacity: 0;
  animation: slide-out 1s;
  pointer-events: none;
}

@keyframes slide-in {
  from {
    opacity: 0;
    translate: 25px;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    translate: 25px;
  }
}

.LandingPageMenu-sign-in:hover {
  background-color: #2e3e51;
}

.LoggedInMenuRight-GoogleProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1200px) {
h1.LandingPageMenu-logo {
    display: none;
  }
}

@media (max-width: 1100px) {
  .LandingPageMenu-main {
    padding: 0px 10px;
    align-items: center;
  }
  .LandingPageMenu-logo {
    /* margin-top: 20px; */
    display: none;
  } 

  .LandingPageMenu-subscription-tier-button-text {
    display: none;
  }
  .LandingPageMenu-subsciption-tier-button {
    /* display: none; */
    width: 75px !important;
  }
  .LoggedInMenuRight-buttons {
    padding: 8px;
  }
}

@media (max-width: 450px) {
  .LoggedInMenuRight-GoogleProfile {
    display: none;
  }
  .LandingPageMenu-subscription-tier-button-text {
    display: none;
  }
}

@media (max-width: 400px) {
  .LandingPageMenu-main button {
    padding: 10px;
  }
}
/* .LandingPageMenu-sign-in {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #00d2ff;
    color: #191a1a;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.2s ease-in-out;
  }
  .LandingPageMenu-try-free {
    margin-right: 0;
  } */
