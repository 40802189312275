.LandingPageBanner-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.LandingPageBanner-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0px 0 20px 0;
  width: 90%;
}

.LandingPageBanner-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 25px;
  color: #eee;
  margin: 0px 0 40px 0;
  width: 100%;
}

@media (max-width: 720px) {
  .LandingPageBanner-title {
    font-size: 30px;
    margin-top: 30px;
  }

  .LandingPageBanner-subtitle {
    font-size: 20px;
  }
}
