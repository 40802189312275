@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@400;500;700&display=swap');
body {
  margin: auto;
  background: #191a1a;
  color: white;
  width: 100%;
  font-family: 'Open Sans', sans-serif !important;
}

::-webkit-scrollbar {
  display: none;
}

.io {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: stretch;
  color: black;
}

.box {
  background: rgba(249, 250, 251, var(--tw-bg-opacity));
  text-align: left;
  width: 40vw;
  height: 40vh;
  min-height: 600px;
  max-width: 1000px;
  border-radius: 0.25rem;
  padding: 2rem;
  margin: 10px;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 00 00),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.advancedBox {
  opacity: 1 !important;
}

@media only screen and (max-width: 1250px) {
  .box {
    width: 50vw;
    min-height: 650px;
    min-width: 300px;
    max-width: 800px;
  }

  .questionMark {
    margin-top: 130px !important;
  }
}

.title {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }
}

.radioGroup {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.radioGroup > div {
  margin: 0 !important;
}

.selector {
  color: #191a1a;
  font-weight: bold;
  background-color: rgba(229, 231, 235, var(--tw-border-opacity));
  width: 100%;
}

.inputTitle {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  color: #191a1a;
}

.react-dropdown-select-content {
  padding-left: 1rem;
}

.radioButton {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.inputImage {
  margin: auto;
  width: 200px;
  transition: 5s all;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  padding-bottom: 7.5%;
}

.nokemonImage {
  width: 250px;
}

/* CSS */
.inputButton {
  align-items: center;
  background-clip: padding-box;
  background-color: rgb(245, 158, 11);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.inputButton:hover,
.inputButton:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.inputButton:hover {
  transform: translateY(-1px);
}

.inputButton:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
.feedbackPanel {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Silkscreen', Inter var, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.feedbackButtons {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.fa {
  font-size: 15px;
}

/* CSS */
.goodButton {
  background-color: #007fff;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: 'Silkscreen' 'Inter var', ui-sans-serif, system-ui, -apple-system,
    system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 6px 25px;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  margin-right: 4px;
}

.goodButton:hover {
  background-color: #374151;
}

.goodButton:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .goodButton {
    padding: 6px 25px;
  }
}

/* CSS */
.badButton {
  background-color: #ff8000;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: 'Silkscreen' 'Inter var', ui-sans-serif, system-ui, -apple-system,
    system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 6px 25px;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.badButton:hover {
  background-color: #374151;
}

.badButton:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .badButton {
    padding: 6px 25px;
  }
}

.opacity-50 {
  opacity: 0.5;
}

.generationIconLoader {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: 1.7s infinite ease, round 1.7s infinite ease;
  animation: 1.7s infinite ease, round 1.7s infinite ease;
}

/* .loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::before {
  content: '';
  display: block;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.5em solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
} */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.progressBar {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-transform: uppercase;
  color: #191a1a;
  padding-bottom: 0.6%;
}

.icon {
  border-radius: 100%;
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 80px;
  transition: 200ms all;
}

.icon:hover {
  filter: saturate(200%);
  transform: scale(1.1);
  cursor: pointer;
}

.icon img {
  height: 60%;
  width: 60%;
  margin: 20%;
}

.bug {
  background: #92bc2c;
  box-shadow: 0 0 20px #92bc2c;
}

.dark {
  background: #595761;
  box-shadow: 0 0 20px #595761;
}

.dragon {
  background: #0c69c8;
  box-shadow: 0 0 20px #0c69c8;
}

.electric {
  background: #f2d94e;
  box-shadow: 0 0 20px #f2d94e;
}

.fire {
  background: #fba54c;
  box-shadow: 0 0 20px #fba54c;
}

.fairy {
  background: #ee90e6;
  box-shadow: 0 0 20px #ee90e6;
}

.fighting {
  background: #d3425f;
  box-shadow: 0 0 20px #d3425f;
}

.flying {
  background: #a1bbec;
  box-shadow: 0 0 20px #a1bbec;
}

.ghost {
  background: #5f6dbc;
  box-shadow: 0 0 20px #5f6dbc;
}

.grass {
  background: #5fbd58;
  box-shadow: 0 0 20px #5fbd58;
}

.ground {
  background: #da7c4d;
  box-shadow: 0 0 20px #da7c4d;
}

.ice {
  background: #75d0c1;
  box-shadow: 0 0 20px #75d0c1;
}

.normal {
  background: #a0a29f;
  box-shadow: 0 0 20px #a0a29f;
}

.poison {
  background: #b763cf;
  box-shadow: 0 0 20px #b763cf;
}

.psychic {
  background: #fa8581;
  box-shadow: 0 0 20px #fa8581;
}

.rock {
  background: #c9bb8a;
  box-shadow: 0 0 20px #c9bb8a;
}

.steel {
  background: #5695a3;
  box-shadow: 0 0 20px #5695a3;
}

.water {
  background: #539ddf;
  box-shadow: 0 0 20px #539ddf;
}

.legendary {
  background: #e60073;
  box-shadow: 0 0 20px #e60073;
}

.shiny {
  background: gold;
  box-shadow: 0 0 20px gold;
}

.typeBar {
  min-width: 95px;
  font-family: 'Silkscreen' 'Inter var', ui-sans-serif, system-ui, -apple-system,
    system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  color: white;
  text-align: center;
  padding: 4px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 16px;
}

#type1 {
  margin-right: 10px;
}

#legendary {
  margin-right: 10px;
}

.footer {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  width: 94%;
  border-radius: 0.25rem;
  margin: auto;
  --tw-bg-opacity: 1;
}

.footer > p > a {
  text-decoration: underline;
  color: inherit;
}

footer {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  width: 94%;
  border-radius: 0.25rem;
  margin: auto;
  --tw-bg-opacity: 1;
}

.outputContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-direction: column;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 650px) {
  .headerFlex {
    font-size: 10px;
  }
  .launchSaleText {
    font-size: 10px;
  }

  .shopDisplayCard {
    width: 250px !important;
  }
}

@media only screen and (max-width: 650px) {
  .headerFlex {
    font-size: 10px;
    padding: 8px;
  }
}

/* CSS */
.dismissButton {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', 'Liberation Sans',
    sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px 0.8em;
  margin-left: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.dismissButton:hover,
.dismissButton:focus {
  background-color: #07c;
}

.dismissButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, 0.15);
}

.dismissButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.glow {
  color: black;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00a2, 0 0 20px #ff00a2,
      0 0 25px #ff00a2, 0 0 30px #ff00a2, 0 0 35px #ff00a2;
  }
  to {
    text-shadow: 0 0 15px #fff, 0 0 20px #e60073, 0 0 25px #e60073,
      0 0 30px #e60073, 0 0 35px #e60073, 0 0 40px #e60073, 0 0 45px #e60073;
  }
}

.outputNokemon {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 600px;
}

.nokemon {
  display: flex;
  justify-content: center;
}

.nokemonTypes {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  font-family: 'Silkscreen';
  font-size: 20px;
  width: 80%;
}
.nokemonGroup > .nokemonImage {
  position: absolute;
  margin-top: 125px;
  width: 256px !important;
}

.nokemonGroup > .nokemon {
  width: 400px;
}

.nokemonGroup > .nokemonName {
  position: absolute;
  margin-top: 0px;
  text-align: center;
  font-family: 'Silkscreen', Inter var, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 20px;
}

.nokemonGroup > .nokemon {
  position: absolute;
  margin-top: 25px;
  text-align: center;
  font-family: 'Silkscreen', Inter var, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 20px;
}

.nokemonCard > .nokemonName {
  text-align: center;
  font-family: 'Silkscreen', Inter var, ui-sans-serif, system-ui, -apple-system,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 20px;
}

.nokemonCard > .nokemonTypes {
  width: 100%;
}

.nokemonCard > .nokemonImage {
  margin: auto;
}

.nokemonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'Silkscreen';
  src: url('slkscre.ttf');
}

#outputButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

#undoPanel {
  align-items: flex-start;
}

.switch {
  display: flex;
  justify-content: flex-start;
}

.switchText {
  margin-top: 0px;
  margin-right: 7px;
}

.nokemonCardInfoGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  justify-content: space-between;
  margin-top: 10px;
}

.nokemonCard {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.8)); */
  background: white;
  width: 300px;
  height: 450px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 30px;
  padding: 30px;
  border-width: 10px;
  border-style: solid;
  border-color: #333;
  font-family: sans-serif;
  position: relative;
}

.shine {
  background-image: -webkit-linear-gradient(
    60deg,
    #0000 33%,
    rgba(255, 255, 255, 0.3) 50%,
    #0000 66%
  );

  background: linear-gradient(
    60deg,
    #0000 33%,
    rgba(255, 255, 255, 0.3) 50%,
    #0000 66%
  );

  background-size: 300% 100%;
  background-position: center;
  color: white;
  transition: background-position 2000ms ease-out;
}

.shine:hover {
  background-position: left center;
  transition: background-position 2000ms ease-out;
}

.border-shine {
  border-radius: 10px;
  -webkit-animation: glowing 1s ease-in-out infinite alternate;
  -moz-animation: glowing 1s ease-in-out infinite alternate;
  animation: glowing 1s ease-in-out infinite alternate;
}
@keyframes glowing {
  from {
    box-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #0ff,
      0 0 10px #e60073, 0 0 10px #e60073, 0 0 10px #e60073;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 10px gold, 0 0 10px gold, 0 0 10px gold,
      0 0 10px gold, 0 0 10px gold, 0 0 10px gold;
  }
}

.nokemonCard:hover {
  transform: scale(1.03);
  box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
}
.nokemonCollection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  padding-bottom: 50px;
}

.collectionSelector {
  color: black;
  min-width: 200px;
  width: 1600px;

  text-align: left;
  font-size: 15px;
  font-weight: 500;
}

.collectionSelector > p {
  font-size: 20px;
  margin-bottom: 4px;
  color: white;
}

.react-tabs {
  -webkit-tap-highlight-color: black;
  border-bottom: 1px solid #aaa;
  width: 1600px;
}

@media only screen and (max-width: 1800px) {
  .nokemonCollection {
    grid-template-columns: repeat(3, 1fr);
  }

  .collectionSelector {
    color: black;
    min-width: 200px;
    width: 1200px;

    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }

  .react-tabs {
    -webkit-tap-highlight-color: black;
    border-bottom: 1px solid #aaa;
    width: 1200px;
  }
}
@media only screen and (max-width: 1400px) {
  .nokemonCollection {
    grid-template-columns: repeat(2, 1fr);
  }

  .collectionSelector {
    color: black;
    min-width: 200px;
    width: 830px;

    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }

  .collectionSelector > p {
    font-size: 20px;
    margin-bottom: 4px;
    color: white;
  }

  .react-tabs {
    -webkit-tap-highlight-color: black;

    border-bottom: 1px solid #aaa;
    width: 830px;
  }
}

@media only screen and (max-width: 900px) {
  .nokemonCollection {
    grid-template-columns: repeat(1, 1fr);
  }

  .collectionSelector {
    color: black;
    min-width: 200px;
    width: 100%;

    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }

  .collectionSelector > p {
    font-size: 20px;
    margin-bottom: 4px;
    color: white;
  }

  .react-tabs {
    -webkit-tap-highlight-color: black;

    border-bottom: 1px solid #aaa;
    width: 100%;
  }
}

.addButton {
  position: absolute;
  margin-top: 420px;
  margin-left: 250px;
  background-color: transparent !important; /* Blue background */
  border: none; /* Remove borders */
  color: rgb(255, 153, 0); /* White text */
  font-size: 34px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

.box > .addButton {
  position: relative;
  margin: 0;
  margin-left: auto;
}

.box > .shareButton {
  margin: 0;
}

/* Darker background on mouse-over */
.addButton:hover {
  background-color: rgba(249, 250, 251);
  color: gold;
}

/* Style buttons */
.deleteButton {
  position: absolute;
  margin-top: 420px;
  margin-left: 250px;
  background-color: rgba(249, 250, 251);
  background-color: transparent !important; /* Blue background */
  border: none; /* Remove borders */
  color: red; /* White text */
  font-size: 34px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over */
.deleteButton:hover {
  color: rgb(168, 2, 2);
  transform: scale(1.05);
}

.shareButton {
  position: absolute;
  margin-top: 420px;
  background-color: rgba(249, 250, 251); /* Blue background */
  background-color: transparent !important; /* Blue background */
  border: none; /* Remove borders */
  color: #4582ec; /* White text */
  font-size: 34px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

.shareButton:hover {
  color: #0157eb;
  transform: scale(1.05);
}

.sharingFeedbackButtons {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
}

.upvoteButton {
  background: none;
  padding: 0;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: rgb(28, 164, 218);
}

.downvoteButton {
  background: none;
  padding: 0;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: rgb(211, 67, 67);
}

.historyBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .collectionSelector {
    color: black;
    min-width: 200px;
    width: 100%;
  }

  .react-tabs {
    width: 100%;
  }
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  height: 50px;
  width: 50%;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191a1a;
  color: white;
}

.react-tabs__tab--selected {
  background: white;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.ribbonContainer {
  position: absolute;
  margin-top: 400px;
  width: 350px;
}

.leftLargeRibbon {
  position: relative;
}

.leftLargeRibbonText {
  position: relative;
  font-size: 1.3em;
  font-family: 'Silkscreen';
  color: white;
  top: -41px;
}

.banner {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.loginContainer {
  display: flex;
  margin: 20px;
}

.sideBarMenuItem {
  font-size: 18px !important;
}

.sideBarSubMenuItem {
  font-size: 18px !important;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  width: 280px;
  cursor: pointer;
}

.loginMessage {
  display: flex;
  align-items: center;
  background: hsl(208, 99%, 50%);
  color: white;
  font-size: 12px;
  padding: 20px;
  height: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loginButton {
  display: flex;
  justify-content: center;
  color: black;
  background: white;
  font-size: 25px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loginButton > img {
  width: 40px;
  margin-right: 15px;
  margin-left: -10px;
}

@media only screen and (max-width: 1250px) {
  #inputBox {
    min-height: 575px;
    max-height: 800px;
  }

  .box {
    min-height: 575px !important;
    height: 600px; 
    max-height: 800px !important;
    width: 65vw;
    min-width: 300px;
    max-width: 800px;
  }

  .icon {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  #outputBox {
    min-height: 600px !important;
    max-height: 800px !important;
  }

  .questionMark {
    font-size: 80px !important;
    margin-top: 85px;
  }
}
@media only screen and (max-width: 800px) {
  .box {
    height: 600px; 
    padding-bottom: 20px;
  }

    #inputBoxAdvanced {
  
      min-height: 575px;
      height: auto;
      max-height: 800px;
    }
}

@media only screen and (max-width: 650px) {
  .react-tabs__tab {
    font-size: 16px !important;
  }
  .box {
    min-height: 575px !important;
    max-height: 850px !important;
  }

  #inputBox {
    min-height: 575px;
    max-height: 800px;
  }
  #inputBoxAdvanced {
    
    min-height: 575px;
    height: auto;
    max-height: 800px;
  }


  .icon {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  #outputBox {
    min-height: 600px !important;
    max-height: 800px !important;
  }

  .questionMark {
    font-size: 80px !important;
    margin-top: 140px !important;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
}

.radioGroup div:nth-of-type(3) > div > div > p {
  color: red !important;
}
.Collapsible {
  background-color: #ede6fd;
}

.Collapsible__contentInner {
  padding: 15px;
  border: 1px solid #ede6fd;
  border-top: 0;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 3px solid #ede6fd;
  padding-left: 20px;
  padding-right: 30px;
  background: #5e18eb;
  color: white;
}
.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
  display: block;
  color: white;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.shopDisplayCard {
  width: 350px;
}

.launchSaleTexth1 {
  font-size: 30px;
}

@media only screen and (max-width: 1025px) {
  .launchSaleTexth1 {
    font-size: 25px;
  }
  .faqText {
    font-size: 15px;
  }
}

@media only screen and (max-width: 730px) {
  .launchSaleTexth1 {
    font-size: 20px;
  }
  .faqText {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .launchSaleTexth1 {
    font-size: 17px;
  }
  .faqText {
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .launchSaleTexth1 {
    font-size: 14px;
  }
  .faqText {
    font-size: 14px;
  }
}

.status-icon > i {
  border-radius: 50%;
  border: 2px solid #61ef61;
  width: 10px;
  height: 10px;
  display: inline-block;

  background-color: #61ef61;
}

.arrow_box {
  position: relative;
  background: #88b7d5;
  border: 4px solid #c2e1f5;
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #88b7d5;
  border-width: 30px;
  margin-left: -30px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 36px;
  margin-left: -36px;
}

.sugimoriCount {
  padding: 10px;

  background-color: rgb(245, 158, 11);
  display: flex;
  align-items: left;
  justify-content: center;
  align-self: flex-end;
  border-radius: 0.25rem;
}

.rodal {
  padding: 0;
  height: 750px;
}

.thumbs-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  display: none !important;
}

.carousel-status {
  color: black !important;
  font-size: 20px !important;
  left: calc(50% - 20px) !important;
  text-shadow: none !important;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif !important;
  top: 83.5% !important;
  padding: 0;
}


/* css */
html {
  scroll-behavior: smooth;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent !important;
}

.AdvancedInput-Header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

@media (max-width: 800px) {
  .AdvancedInput-Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}

.Toastify__toast-container {
  z-index: 1200000000 !important;
}

/* Style the tooltip */
[title] {
  position: relative;
}
.circle-question-mark {
  display: inline-block;
  background-color: rgb(255, 166, 0); /* Change to desired color */
  color: white; /* Change to desired color */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-left: 5px;
}

.subscription-io-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
}
@media (max-width: 1250px) {
  .subscription-io-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

