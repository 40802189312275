.receiptBorder {
  min-width: 600px;
}

.ReceiptHeader-container {
  font-size: 12px;
  padding: 0 20px 0 20px;
}
.ReceiptHeader-container > h1 {
  color: black;
  font-size: 20px;
}

.ReceiptHeader-container > h2 {
  font-size: 15px;
}

.ReceiptHeader-back-button-container {
  display: flex;
  flex-direction: row;
  color: white;
  position: relative;
  text-align: left;
  width: 100%;
  gap: 5px;
  margin-left: 10px;
  text-decoration: none;
}

.ReceiptInformation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.receiptIncludesListElement {
  height: 70px;
}

.ReceiptHeader-emblem-img {
  height: 150px;
}

.ReceiptHeader-SVGComponent-container > svg {
  height: 120px;
}

.receiptInformationLine {
  font-size: 10px;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 912px) {
  .receiptBorder {
    width: 90vw;
    min-width: 0;
  }
  .ReceiptHeader-container > h1 {
  }

  .ReceiptHeader-container > .receiptHeaderIcon {
    font-size: 100px;
  }

  .receiptInformationLine > h2 {
  }

  .ReceiptInformation-container {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .receiptBorder {
    width: 90vw;
    min-width: 0;
  }
  .ReceiptHeader-container > h1 {
  }

  .ReceiptHeader-container > .receiptHeaderIcon {
    font-size: 100px;
  }

  .receiptInformationLine > h2 {
  }

  .ReceiptInformation-container {
    width: 80%;
  }
}

@media only screen and (max-width: 512px) {
  .receiptBorder {
    width: 90vw;
    min-width: 0;
  }

  .ReceiptHeader-container > h1 {
    font-size: 15px;
  }

  .ReceiptHeader-container > .receiptHeaderIcon {
    font-size: 75px;
  }

  .ReceiptInformation-container {
    width: 80%;
  }

  .receiptInformationLine > h2 {
    font-size: 12px;
  }
}
