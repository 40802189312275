.MarketplacePageBanner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #191a1a;
  text-align: center;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
}

.MarketplacePageBanner-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #191a1a;
  width: 80%;
}

.MarketplacePageBanner-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: white;
  margin: 0px 0 20px 0;
}

.MarketplacePageBanner-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  color: white;
  margin: 20px 0 0px 0;
}

@media (max-width: 720px) {
  .MarketplacePageBanner-title {
    margin-top: 30px;
  }

  .MarketplacePageBanner-subtitle {
    font-size: 20px;
  }
}
