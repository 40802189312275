.MarketplaceTabs-tabs {
  width: 100%;
  border-radius: 0px !important;
}
.MarketplaceTabs-tabList {
  height: 50px;
  padding: 0px !important;
  border-radius: 0px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
  margin-bottom: 80px;
  margin-top: 0px;
}

.MarketplaceTabs-tab {
  height: 75px;
  width: 50%;
  text-align: center;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  border-bottom: none;
  position: relative;
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191a1a;
  color: white;
  font-size: 23px;
  border-radius: 0px !important;
}

.MarketplaceTabs-tab:hover {
  background-color: #5e18eb;
  color: #fff;
}

.MarketplaceTabs-tab.react-tabs__tab--selected {
  background-color: white;
  color: black;
}

@media (max-width: 767px) {
  .MarketplaceTabs-tabList {
    height: 75px;
    margin-bottom: 50px;
  }

  .MarketplaceTabs-tab {
    height: 75px;
    font-size: 20px;
    width: 100%;
  }
  .MarketplaceTabs-tabs {
    width: 100%;
  }
}
