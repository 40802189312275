.StoragePageBanner-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  padding-top: 20px;
}

.StoragePageBanner-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  margin: 0px 0 20px 0;
}

.StoragePageBanner-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 25px;
  color: #eee;
  margin: 20px 0 40px 0;
}

@media (max-width: 720px) {
  .StoragePageBanner-main {
    width: 90%;
  }
  .StoragePageBanner-title {
    font-size: 40px;
  }

  .StoragePageBanner-subtitle {
    font-size: 20px;
  }
}
