.ShopTabDisplayText-main {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ShopTabDisplayText-title {
  color: black;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
}

.ShopTabDisplayText-subtitle {
  color: black;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.ShopTabDisplayText-text {
  color: black;
  font-size: 22px;
  text-align: justify;
}
.ShopTabDisplayText-main {
  position: relative;
  display: flex;
  align-items: center;
}

.ShopTabDisplayText-text {
  height: fit-content;
  font-size: 1.2rem;
  line-height: 1.5;
  display: -webkit-box;
  margin-bottom: 40px;
}

.ShopTabDisplayText-text.fade {
  height: 75px;
  display: block;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ShopTabDisplayText-button {
  display: block;
  margin: 0 auto 0;
  padding: 10px 20px;
  background-color: #5e18eb;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 50px;
  margin-top: -60px;
}

.ShopTabDisplayText-button:hover {
  background-color: #5e18eb;
}

@media (max-width: 768px) {
  .ShopTabDisplayText-title {
    color: black;
    text-align: center;
    font-weight: bold;
  }

  .ShopTabDisplayText-subtitle {
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
}

@media (max-width: 590px) {
  .ShopTabDisplayText-title {
    color: black;
    text-align: center;
    font-size: 37px;
    font-weight: bold;
  }

  .ShopTabDisplayText-subtitle {
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
}

@media (max-width: 400px) {
  .ShopTabDisplayText-title {
    color: black;
    text-align: center;
    font-size: 33px;
    font-weight: bold;
  }

  .ShopTabDisplayText-subtitle {
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
}
